import { css, SerializedStyles } from '@emotion/core';

/**
 * Spacing styling
 */
import { Size, SizeMultiplier } from './sizes.styles';

type SpacingPosition =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'horizontal'
  | 'vertical';

const margin = (spacingPosition: SpacingPosition, multiplier: number) =>
  css`
    margin-${spacingPosition}: calc(var(--unit) * ${multiplier});
  `;

const horizontalMargin = (multiplier: number) =>
  css`
    margin-left: calc(var(--unit) * ${multiplier});
    margin-right: calc(var(--unit) * ${multiplier});
  `;

const verticalMargin = (multiplier: number) =>
  css`
    margin-top: calc(var(--unit) * ${multiplier});
    margin-bottom: calc(var(--unit) * ${multiplier});
  `;

const getStyle = (spacingPosition: SpacingPosition, key: Size) => {
  switch (spacingPosition) {
    case 'horizontal':
      return horizontalMargin(SizeMultiplier[key]);
    case 'vertical':
      return verticalMargin(SizeMultiplier[key]);
    default:
      return margin(spacingPosition, SizeMultiplier[key]);
  }
};

type GetSizeMapFn = (
  spacingPosition: SpacingPosition,
) => Record<Size, SerializedStyles>;
const getSizeMap: GetSizeMapFn = (spacingPosition) => ({
  small: css`
    ${getStyle(spacingPosition, 'small')}
  `,
  medium: css`
    ${getStyle(spacingPosition, 'medium')}
  `,
  large: css`
    ${getStyle(spacingPosition, 'large')}
  `,
  xlarge: css`
    ${getStyle(spacingPosition, 'xlarge')}
  `,
  xxlarge: css`
    ${getStyle(spacingPosition, 'xxlarge')}
  `,
  xxxlarge: css`
    ${getStyle(spacingPosition, 'xxxlarge')}
  `,
});

export const spacing = {
  top: getSizeMap('top'),
  right: getSizeMap('right'),
  bottom: getSizeMap('bottom'),
  left: getSizeMap('left'),
  horizontal: getSizeMap('horizontal'),
  vertical: getSizeMap('vertical'),
};
