import { css } from '@emotion/core';

export const base = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  touch-action: manipulation;
  user-select: none;
  cursor: pointer;

  white-space: nowrap;
  text-decoration: none;

  border: 0;
  background: transparent;
  padding: 0;

  font-size: 16px;
  height: 60px;

  transition: all 0.2s ease-out;

  &:focus {
    outline: 0;
  }

  &.is-disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.is-disabled {
    pointer-events: none;
  }
`;

export const primary = css`
  background: var(--color-primary);
  color: white;
  border-radius: 30px;
  padding: 0 120px;

  &:hover,
  &:focus {
    background: black;
    color: white;
  }
`;

export const neutral = css`
  background: #f0f0f0;
  border-radius: 30px;
  padding: 0 120px;

  &:hover,
  &:focus {
    background: black;
    color: white;
  }
`;

export const dark = css`
  background: black;
  color: white;
  border-radius: 30px;
  padding: 0 120px;

  &:hover,
  &:focus {
    background: var(--color-primary);
    color: white;
  }
`;

export const ghostNegative = css`
  background: transparent;
  color: white;
  padding: 0px;

  &:hover,
  &:focus {
    color: #c0c0c0;
  }
`;

export const narrowButton = css`
  padding: 0 55px;
`;
