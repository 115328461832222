import { InStoreExperienceReducer } from 'modules/inStore';
import { LanguageReducer } from 'modules/language/redux/reducer';
import { MeasurementReducer } from 'modules/measurements';
import { SportsReducer } from 'modules/sports';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const configureStore = () => {
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * Merge all reducers into a single object
   */
  const rootReducer = {
    language: LanguageReducer,
    measurements: MeasurementReducer,
    sports: SportsReducer,
    inStoreExperience: InStoreExperienceReducer,
  };

  /**
   * We'll create our store with the combined reducers and all enchancers
   */
  return createStore(
    combineReducers(rootReducer),
    {},
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(thunk)
      : composeEnhancers(applyMiddleware(thunk)),
  );
};

export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>;
