import { Global } from '@emotion/core';
import { Layout } from 'components';
import { IntlProvider } from 'gatsby-plugin-intl';
import { configureStore } from 'modules/redux-store';
import 'normalize.css';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { globals } from 'style';
import ReactGA from 'react-ga';

/**
 *
 * @param element root node element
 * @description Used for wrapping the root node with all of the providers that should be available in the app and avoid remounting
 */
export const wrapRootElement = ({ element }) => {
  const store = configureStore();
  return (
    <CookiesProvider>
      <IntlProvider>
        <Global styles={globals} />
        <Provider store={store}>{element}</Provider>
      </IntlProvider>
    </CookiesProvider>
  );
};

/**
 *
 * @param element current page element
 * @param props gatsby injected props such as location
 * @description Used for wrapping all pages inside a shared layout. Layout component renders the content partially inside the <main /> html tag.
 */
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  /** This fetches ; delimited cookies from browser and exposes them as object to check for values. */
  const cookies = document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=').map((c) => c.trim());
    cookies[name] = value;
    return cookies;
  }, {});

  if (cookies['nike-bra-analytics-cookie-consent']) {
    ReactGA.initialize('UA-180558610-1');
  }
};

export const onRouteUpdate = ({ location }) => {
  ReactGA.pageview(location.pathname + location.search);
};
