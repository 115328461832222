import { css } from '@emotion/core';
import { bp } from 'style';

/* Wrapper */
export const wrapper = css`
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1440px;

  @media ${bp.fromLarge} {
    padding: 0 30px;
  }
`;

/* Opacity */
export const opacity80 = css`
  opacity: 0.8;
`;

export const opacity60 = css`
  opacity: 0.6;
`;

export const opacity40 = css`
  opacity: 0.4;
`;

/* Messages */
export const messageWarning = css`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-warning);
`;

/* Typography */

export const fontFamilySans = css`
  font-family: var(--ff-sans);
`;

export const fontFamilySansAlt = css`
  font-family: var(--ff-sans-alt);
`;

export const fontFamilySerif = css`
  font-family: var(--ff-serif);
`;

export const typeCenter = css`
  text-align: center;
`;

export const typeUnderline = css`
  text-decoration: underline;
`;

export const typeUpper = css`
  text-transform: uppercase;
`;

export const typeUpperExtended = css`
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

/* Formatted text */
export const formatted = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p + p {
    margin-bottom: 24px;
  }
`;

/* Type scale */

export const typeBase = css`
  font-size: 16px;
  line-height: 24px;
`;

export const typeMedium = css`
  font-size: 20px;
  line-height: 28px;
`;

export const typeLarge = css`
  font-size: 24px;
  line-height: 30px;
`;

export const typeNegative = css`
  color: #fff;
`;

/* Fluid typography */
export const fluidTypeBase = css`
  font-size: calc(0.875rem + (16 - 14) * ((100vw - 320px) / (1400 - 320)));
  line-height: calc(1.25rem + (24 - 20) * ((100vw - 320px) / (1400 - 320)));
  min-height: 0vw;

  @media (min-width: 1400px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const fluidTypeMedium = css`
  font-size: calc(1rem + (20 - 16) * ((100vw - 320px) / (1400 - 320)));
  line-height: calc(1.5rem + (28 - 24) * ((100vw - 320px) / (1400 - 320)));
  min-height: 0vw;

  @media (min-width: 1400px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const fluidTypeLarge = css`
  font-size: calc(1rem + (25 - 16) * ((100vw - 320px) / (1400 - 320)));
  line-height: calc(1.5rem + (36 - 24) * ((100vw - 320px) / (1400 - 320)));
  min-height: 0vw;

  @media (min-width: 1400px) {
    font-size: 25px;
    line-height: 36px;
  }
`;

export const fluidTypeXLarge = css`
  font-size: calc(1.25rem + (31 - 20) * ((100vw - 320px) / (1400 - 320)));
  line-height: calc(1.75rem + (40 - 28) * ((100vw - 320px) / (1400 - 320)));
  min-height: 0vw;

  @media (min-width: 1400px) {
    font-size: 31px;
    line-height: 40px;
  }
`;

export const fluidTypeXXLarge = css`
  font-size: calc(1.5625rem + (39 - 25) * ((100vw - 320px) / (1400 - 320)));
  line-height: calc(2.25rem + (44 - 36) * ((100vw - 320px) / (1400 - 320)));
  min-height: 0vw;

  @media (min-width: 1400px) {
    font-size: 39px;
    line-height: 44px;
  }
`;

export const fluidTypeXXXLarge = css`
  font-size: calc(1.9375rem + (49 - 31) * ((100vw - 320px) / (1400 - 320)));
  line-height: calc(2.5rem + (56 - 40) * ((100vw - 320px) / (1400 - 320)));
  min-height: 0vw;

  @media (min-width: 1400px) {
    font-size: 49px;
    line-height: 56px;
  }
`;

export const disabled = css`
  pointer-events: none;
`;

/** Move this in component */
export const selectedItem = css`
  border: 1px solid red;
`;

export const switchComponent = css``;

export const switchInput = css`
  opacity: 0;

  &:checked + label {
    &:after {
      background: #fbcfcd;
    }

    &:before {
      right: 4px;
      background: var(--color-primary);
    }
  }
`;

export const switchLabel = css`
  position: relative;
  display: block;

  &:after {
    position: absolute;
    top: 0;
    right: 0;

    content: '';
    display: block;
    height: 30px;
    width: 52px;
    background: #dddddd;
    border-radius: 100px;
  }

  &:before {
    position: absolute;
    top: 3px;
    right: 24px;
    z-index: 1;

    transition: all 0.2s ease-out;

    content: '';
    display: block;
    height: 24px;
    width: 24px;
    background: #7d7d7d;
    border-radius: 100px;
  }
`;
