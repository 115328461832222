import { InStoreExperienceActions } from '.';
import { InStoreExperienceActionTypes } from './types';

export interface InStoreExperienceState {
  inStoreExperienceActive: boolean;
}

const INITIAL_STATE: InStoreExperienceState = {
  inStoreExperienceActive: false,
};

export const InStoreExperienceReducer = (
  state: InStoreExperienceState = INITIAL_STATE,
  action: InStoreExperienceActions,
): InStoreExperienceState => {
  switch (action.type) {
    case InStoreExperienceActionTypes.StartInStoreExperience:
      return {
        ...state,
        inStoreExperienceActive: true,
      };
    case InStoreExperienceActionTypes.StopInStoreExperience:
      return {
        ...state,
        inStoreExperienceActive: false,
      };
    default:
      return state;
  }
};
