import { MeasurementAction } from './actions';
import { MeasurementActionTypes } from './types';

export interface MeasurementState {
  ribMeasure?: number;
  bandMeasure?: number;
  cupSize?: string;
}

const INITIAL_STATE: MeasurementState = {
  ribMeasure: undefined,
  bandMeasure: undefined,
  cupSize: undefined,
};

export const MeasurementReducer = (
  state: MeasurementState = INITIAL_STATE,
  action: MeasurementAction,
): MeasurementState => {
  switch (action.type) {
    case MeasurementActionTypes.AddBandMeasure:
      return {
        ...state,
        bandMeasure: action.payload.bandMeasure,
      };
    case MeasurementActionTypes.AddCupSize:
      return {
        ...state,
        cupSize: action.payload.cupSize,
      };
    case MeasurementActionTypes.AddRibMeasure:
      return {
        ...state,
        ribMeasure: action.payload.ribMeasure,
      };
    case MeasurementActionTypes.CleanAllMeasurements:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
