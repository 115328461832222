import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import { buttons, spacing, utils } from 'style';
import { cookie } from 'style/cookie.styles';
import { fontFamilySansAlt } from 'style/utils.styles';

export const CookiePopup: React.FC = () => {
  const intl = useIntl();
  const [cookies, setCookie] = useCookies([
    'nike-bra-analytics-cookie-consent',
  ]);
  const [cookiesFromParent, setCookiesFromParent] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('message', (e: any) => {
      console.log(e.data);
      setCookiesFromParent(true);
    });
  });

  if (cookies['nike-bra-analytics-cookie-consent'] || cookiesFromParent) {
    return null;
  }

  return (
    <section css={cookie.popup}>
      <p css={[utils.typeNegative, fontFamilySansAlt]}>
        <FormattedMessage id="cookiePopupExplainerTop" />
      </p>
      <button
        onClick={allowCookies}
        css={[buttons.base, buttons.ghostNegative, spacing.right.medium]}
      >
        <FormattedMessage id="cookiePopupAcceptCta" />
      </button>
      <Link to={`/cookie-settings`} css={[buttons.base, buttons.ghostNegative]}>
        <FormattedMessage id="cookiePopupSettingsCta" />
      </Link>

      <p css={[spacing.top.medium, utils.typeNegative, fontFamilySansAlt]}>
        <FormattedMessage
          id="cookieDetailsLink"
          values={{
            link: (
              <a
                target="_blank noopener noreferrer"
                css={[utils.typeNegative, utils.typeUnderline]}
                href={intl.formatMessage({ id: 'privacyPolicyLink' })}
              >
                <FormattedMessage id="privacyAndCookiePolicy" />
              </a>
            ),
          }}
        />
      </p>
    </section>
  );

  function allowCookies() {
    setCookie('nike-bra-analytics-cookie-consent', true);

    window.parent.postMessage(
      {
        msg: 'nike-bra-analytics-cookie-consent',
      },
      '*',
    );

    ReactGA.initialize('UA-180558610-1');
    ReactGA.pageview(location.pathname + location.search);
  }
};
