import { motion } from 'framer-motion';
import { LanguageSwitcher } from 'modules/language/components';
import React from 'react';
import { header, utils } from 'style';

import { BackButton } from './BackButton';
import { ShareButton } from './ShareButton';

interface Props {
  location: Location;
}

export const Header: React.FC<Props> = ({ location }) => {
  return (
    <header css={header.root}>
      <div css={header.top}></div>
      <div css={utils.wrapper}>
        <div css={header.actionbar}>
          <div css={header.backButton}>
            <BackButton location={location} />
          </div>
          <motion.div
            css={header.menu}
            animate={{ y: [-30, 0], opacity: [0, 1] }}
            transition={{ duration: 1.5, delay: 4, ease: 'circOut' }}
          >
            <LanguageSwitcher location={location} />
            <ShareButton />
          </motion.div>
        </div>
      </div>
    </header>
  );
};
