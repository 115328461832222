// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-band-measure-tsx": () => import("./../../../src/pages/band-measure.tsx" /* webpackChunkName: "component---src-pages-band-measure-tsx" */),
  "component---src-pages-cookie-settings-tsx": () => import("./../../../src/pages/cookie-settings.tsx" /* webpackChunkName: "component---src-pages-cookie-settings-tsx" */),
  "component---src-pages-cup-size-tsx": () => import("./../../../src/pages/cup-size.tsx" /* webpackChunkName: "component---src-pages-cup-size-tsx" */),
  "component---src-pages-find-your-fit-tsx": () => import("./../../../src/pages/find-your-fit.tsx" /* webpackChunkName: "component---src-pages-find-your-fit-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-measure-type-tsx": () => import("./../../../src/pages/measure-type.tsx" /* webpackChunkName: "component---src-pages-measure-type-tsx" */),
  "component---src-pages-measure-up-tsx": () => import("./../../../src/pages/measure-up.tsx" /* webpackChunkName: "component---src-pages-measure-up-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */),
  "component---src-pages-rib-measure-tsx": () => import("./../../../src/pages/rib-measure.tsx" /* webpackChunkName: "component---src-pages-rib-measure-tsx" */),
  "component---src-pages-select-your-sports-tsx": () => import("./../../../src/pages/select-your-sports.tsx" /* webpackChunkName: "component---src-pages-select-your-sports-tsx" */)
}

