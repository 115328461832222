import { changeLocale } from 'gatsby-plugin-intl';
import { Languages } from 'modules/language';
import React from 'react';
import { header } from 'style';

interface Props {
  location: Location;
}

export const LanguageSwitcher: React.FC<Props> = ({ location }) => {
  function handleLanguageChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    if (!value) {
      return;
    }

    changeLocale(value);
  }

  return (
    <select
      value={location.pathname.split('/')[1]}
      onChange={handleLanguageChange}
      css={header.select}
    >
      <option value={Languages.EN}>ENG</option>
      <option value={Languages.DE}>DEU</option>
      {/* <option value={Languages.FR}>FRA</option> */}
      {/* <option value={Languages.IT}>ITA</option> */}
      {/* <option value={Languages.NL}>NLD</option> */}
    </select>
  );
};
