import { css } from '@emotion/core';

export const root = css`
  margin-top: 150px;
  padding: 30px;
  background: #f0f0f0;

  ul li {
    margin-bottom: 12px;
  }
`;
