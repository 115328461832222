import { Languages } from '../models/Languages';
import { LanguageAction } from './actions';
import { LanguageActionTypes } from './types';

export interface LanguageState {
  selectedLanguage: Languages;
}

const INITIAL_STATE: LanguageState = {
  selectedLanguage: Languages.EN,
};

export const LanguageReducer = (
  state: LanguageState = INITIAL_STATE,
  action: LanguageAction,
): LanguageState => {
  switch (action.type) {
    case LanguageActionTypes.Success:
      return {
        ...state,
        selectedLanguage: Languages.EN,
      };
    default:
      return state;
  }
};
