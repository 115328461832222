import { css } from '@emotion/core';

export const root = css``;

export const title = css`
  max-width: 90px;
  margin-bottom: 50px;
`;

export const items = css`
  @media (max-width: 22em) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const button = css`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 0;
  margin: 0 10px 10px 0;

  background: #f0f0f0;
  text-align: center;

  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-out;

  @media (min-width: 23.4375em) {
    width: 165px;
    height: 165px;
  }

  @media (min-width: 25em) {
    width: 172px;
    height: 172px;
  }

  &:focus {
    outline: 0;
  }
`;

export const buttonSelected = css`
  background: black;
  color: white;
  transition: all 0.2s ease-out;

  svg {
    filter: invert(1);
  }

  &:hover {
    background: black;
    color: white;
  }
`;
