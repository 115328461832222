import { css } from '@emotion/core';

export const root = css`
  max-width: 600px;
  margin: 0 auto;
`;

export const centered = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
`;

export const header = css`
  padding-top: 50px;
  margin-bottom: 25px;
`;

export const content = css``;
