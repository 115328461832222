import { ActionUnion, createAction } from 'modules/redux-store';

import { Sports } from '../models';
import { SportActionTypes } from './types';

export const SportActions = {
  addSport: (sport: Sports) =>
    createAction(SportActionTypes.AddSport, { sport }),
  removeSport: (sport: Sports) =>
    createAction(SportActionTypes.RemoveSport, { sport }),
  cleanAllSports: () => createAction(SportActionTypes.CleanAllSports),
};

export type SportAction = ActionUnion<typeof SportActions>;
