import React from 'react';
import { header } from 'style';

export const ShareButton: React.FC = () => {
  /** This is a fix for Gatsby SSR. */
  if (typeof navigator === 'undefined') {
    return null;
  }

  /** Disable button for devices that do not support native sharing. */
  if (!navigator.share) {
    return null;
  }

  return (
    <button onClick={handleShare} css={header.shareButton}>
      <img src={require('assets/images/share-icon.svg')} alt="Share" />
    </button>
  );

  function handleShare() {
    if (navigator.share) {
      navigator
        .share({
          title: 'You Need A Sports Bra With That!',
          text:
            'Nike Bra Experience is a tool to help you choose a right Sports Bra support level for you.',
          url: 'https://find-your-fit.eu/en/',
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }
};
