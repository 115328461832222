import { css } from '@emotion/core';
import { bp } from 'style';

export const root = css`
  @media ${bp.fromSmall} {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  }
`;

export const items = css`
  position: relative;

  @media ${bp.toSmall} {
    padding: 0 80px 0 50px;
  }

  @media ${bp.fromSmall} {
    display: flex;
    justify-content: space-around;
    min-width: 480px;
  }
`;

export const item = css`
  max-width: 175px;

  button {
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    text-align: left;

    &:focus {
      outline: 0;
    }
  }

  img {
    margin-top: 4px;
  }

  h2,
  img {
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  }

  &:hover {
    h2 {
      color: var(--color-primary);
    }

    img {
      transform: translateX(10px);
    }
  }

  @media ${bp.toSmall} {
    margin: 0 auto;

    &:first-child {
      padding-bottom: 50px;
    }
  }
`;

export const line = css`
  background: black;

  @media ${bp.toSmall} {
    width: 100%;
    height: 1px;
    margin-bottom: 50px;
  }

  @media ${bp.fromSmall} {
    position: absolute;
    top: 0;
    left: 47.5%;
    height: 100%;
    width: 1px;
  }
`;
