import { css } from '@emotion/core';

export const cookie = {
  popup: css`
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: black;
    width: 100%;
    padding: 35px;
  `,
};
