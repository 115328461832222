import { css } from '@emotion/core';

export const button = css`
  position: fixed;
  bottom: 20px;
  right: 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  padding: 10px 36px;
  font-size: 16px;
  line-height: 23px;
  border-radius: 100px;
  border: 0;
  background: var(--color-primary);
  color: white;
  cursor: pointer;

  transition: all 0.2s ease-out;

  &:focus {
    outline: 0;
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      background: black;
      color: white;
    }
  }
`;
export const buttonHiddenByDefault = css`
  opacity: 0;
`;
