export const bp = {
  toTiny: '(max-width: 26.25em)',
  toSmall: '(max-width: 34em)',
  toMedium: '(max-width: 48em)',
  toLarge: '(max-width: 62em)',
  toXlarge: '(max-width: 75em)',
  toXXLarge: '(max-width: 90em)',
  toXXXLarge: '(max-width: 120em)',
  fromTiny: '(min-width: 26.3125em)',
  fromSmall: '(min-width: 34.0625em)',
  fromMedium: '(min-width: 48.0625em)',
  fromLarge: '(min-width: 62.0625em)',
  fromXlarge: '(min-width: 75.0625em)',
  fromXXLarge: '(min-width: 90.0625em)',
  fromXXXLarge: '(min-width: 120.0625em)',
};
