import { css } from '@emotion/core';
import { bp } from 'style';

export const root = css``;

export const title = css`
  font-size: calc(2.375rem + (45 - 38) * ((100vw - 320px) / (1000 - 320)));
  line-height: calc(2rem + (40 - 32) * ((100vw - 320px) / (1000 - 320)));
  min-height: 0vw;

  @media (min-width: 1000px) {
    font-size: 45px;
    line-height: 40px;
  }
`;

export const carousel = css`
  position: relative;
  height: 550px;
  margin-bottom: 24px;
  background: #f7f7f7;
`;

export const carouselTitle = css`
  position: absolute;
  z-index: 10;
  bottom: 25px;
  left: 20px;
  max-width: 120px;
`;

export const carouselTitleAlt = css`
  position: absolute;
  z-index: 10;
  top: 30px;
  left: 20px;
  max-width: 120px;
`;

export const carouselItem = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 550px;

  overflow: hidden;

  img {
    position: absolute;
    bottom: 0;
    max-height: 550px;
    width: 550px;

    @media ${bp.toTiny} {
      max-width: 140%;
      right: -60%;
    }

    @media ${bp.fromTiny} {
      right: -20%;
    }

    @media ${bp.fromMedium} {
      right: 20%;
    }
  }
`;

export const content = css`
  max-width: 260px;
`;
