enum MeasurementTypes {
  AddRibMeasure = 'measurement/rib-measure',
  AddBandMeasure = 'measurement/band-measure',
  AddCupSize = 'measurement/cup-size',
  CleanAllMeasurements = 'measurement/clean-all',
}

export const MeasurementActionTypes = {
  ...MeasurementTypes,
};
