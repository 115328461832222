import { css } from '@emotion/core';

export const primary = css`
  font-size: 45px;
  line-height: 40px;
`;

export const secondary = css`
  font-size: 40px;
  line-height: 40px;
`;

export const tertiary = css`
  font-size: 38px;
  line-height: 34px;
`;
