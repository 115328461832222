import React from 'react';
import { redLoading } from 'style/redloading.styles';

export const RedLoading: React.FC = () => {
  return (
    <div css={redLoading.root}>
      <div css={redLoading.wrapper}>
        <div css={redLoading.itemsWrap}>
          <img
            src={require('assets/images/preloader-states/nike-bra-1.svg')}
            alt=""
            css={[redLoading.bra]}
          />
          <img
            src={require('assets/images/preloader-states/nike-bra-2.svg')}
            alt=""
            css={[redLoading.bra]}
          />
          <img
            src={require('assets/images/preloader-states/nike-bra-3.svg')}
            alt=""
            css={[redLoading.bra]}
          />
        </div>
      </div>
    </div>
  );
};
