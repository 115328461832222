import { css } from '@emotion/core';

export const redLoading = {
  root: css`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    background: var(--color-primary);
    animation-name: fadeOut;
    animation-duration: 3.5s;
    animation-iteration-count: 1;
    opacity: 0;
    pointer-events: none;

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }

      60% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  `,

  wrapper: css`
    @keyframes animateRotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -30px;
      top: -30px;
      border-radius: 50%;
      border-top: 1px solid hsla(0, 0%, 0%, 0.3);
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;

      width: 160px;
      height: 160px;

      animation-name: animateRotation;
      animation-duration: 0.7s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  `,

  itemsWrap: css`
    position: relative;
    width: 100px;
    height: 100px;
  `,

  bra: css`
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100px;

    animation-name: animateBra;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    background: var(--color-primary);

    &:nth-child(1) {
      animation-delay: 0;
    }

    &:nth-child(2) {
      animation-delay: 0.9s;
    }

    &:nth-child(3) {
      animation-delay: 1.8s;
    }

    @keyframes animateBra {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 0;
      }
    }
  `,

  deco: css`
    height: 60px;
    width: 24px;
  `,
};
