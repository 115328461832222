import { ActionUnion, createAction } from 'modules/redux-store';

import { MeasurementActionTypes } from './types';

export const MeasurementActions = {
  addBandMeasure: (bandMeasure: number) =>
    createAction(MeasurementActionTypes.AddBandMeasure, { bandMeasure }),
  addCupSize: (cupSize: string) =>
    createAction(MeasurementActionTypes.AddCupSize, { cupSize }),
  addRibMeasure: (ribMeasure: number) =>
    createAction(MeasurementActionTypes.AddRibMeasure, { ribMeasure }),
  cleanAllMeasurements: () =>
    createAction(MeasurementActionTypes.CleanAllMeasurements),
};

export type MeasurementAction = ActionUnion<typeof MeasurementActions>;
