import { css } from '@emotion/core';
import { bp } from 'style';

export const root = css`
  max-width: 625px;
  margin: 0 auto;
`;

export const visual = css`
  margin-bottom: 30px;

  position: relative;
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(360 / 360 * 100%);

  img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: auto;
  }
`;

export const title = css`
  max-width: 150px;
  margin-bottom: 30px;
`;

export const content = css`
  max-width: 260px;
  margin-bottom: 40px;
`;

export const field = css`
  width: 172px;
  text-align: center;

  @media ${bp.toSmall} {
    margin: 0 auto;
  }

  @media ${bp.fromSmall} {
    display: flex;
    align-items: center;
    width: 240px;
  }
`;

export const input = css`
  border: 0;
  padding: 0;

  width: 172px;
  height: 172px;
  font-size: 60px;
  line-height: 172px;
  text-align: center;
  font-family: var(--ff-sans-alt);
  text-transform: uppercase;
  border-radius: 50%;
  background: #f0f0f0;
  margin-bottom: 10px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    background: #000;
    color: white;
    outline: 0;
  }

  &::placeholder {
    text-transform: none;
    color: hsla(0, 0%, 0%, 0.2);
  }

  @media ${bp.fromSmall} {
    margin-right: 12px;
  }
`;

export const validation = css`
  @media ${bp.fromSmall} {
    max-width: 172px;
  }
`;
