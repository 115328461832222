import { ActionUnion, createAction } from 'modules/redux-store';

import { InStoreExperienceActionTypes } from './types';

export const InStoreExperienceActions = {
  StartInStoreExperience: () =>
    createAction(InStoreExperienceActionTypes.StartInStoreExperience),
  StopInStoreExperience: () =>
    createAction(InStoreExperienceActionTypes.StopInStoreExperience),
};

export type InStoreExperienceActions = ActionUnion<
  typeof InStoreExperienceActions
>;
