import { css } from '@emotion/core';
import nikeLogoPattern from 'assets/images/nike-logo-pattern.svg';

export const root = css`
  @keyframes animatedBackground {
    from {
      background-position: 0 50%;
    }

    to {
      background-position: 200px 50%;
    }
  }
`;

export const top = css`
  height: 50px;
  transform: translate3d(0, 0, 0);
  background-image: url(${nikeLogoPattern});
  background-repeat: repeat-x;
  background-color: var(--color-primary);
  background-size: 200px;
  animation: animatedBackground 3s linear infinite;
`;

export const actionbar = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`;

export const menu = css`
  display: flex;
`;

export const backButton = css`
  button {
    height: 40px;
    width: 40px;
    padding: 0;
    background: transparent;
    border: 0;

    img {
      min-height: 15px;
    }

    &:focus {
      outline: 0;
    }
  }
`;

export const shareButton = css`
  height: 40px;
  width: 40px;
  margin-left: 12px;
  background: transparent;
  border: 0;
  padding: 0;

  &:focus {
    outline: 0;
  }
`;

export const select = css`
  border: 0;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIj48dGl0bGU+ZG93biBhcnJvdzwvdGl0bGU+PGcgY2xhc3M9Im5jLWljb24td3JhcHBlciIgZmlsbD0iIzAwMDAwMCI+PHBhdGggZD0iTTEwLjI5MywzLjI5Myw2LDcuNTg2LDEuNzA3LDMuMjkzQTEsMSwwLDAsMCwuMjkzLDQuNzA3bDUsNWExLDEsMCwwLDAsMS40MTQsMGw1LTVhMSwxLDAsMSwwLTEuNDE0LTEuNDE0WiIgZmlsbD0iIzAwMDAwMCIvPjwvZz48L3N2Zz4=)
    no-repeat 95% 48%;
  padding: 10px 20px 10px 10px;
`;
