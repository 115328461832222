import { css } from '@emotion/core';
import CanelaThin from 'assets/fonts/Canela-Thin.woff';
import HelveticaBold from 'assets/fonts/Helvetica-Bold.woff';

export const globals = css`
  :root {
    --color-primary: hsl(2, 85%, 58%);
    --color-secondary: red;

    --color-warning: hsl(2, 85%, 58%);
    --color-success: red;
    --color-note: red;

    --color-text: hsla(0, 0%, 0%, 1);
    --color-bg: white;
    --color-bg-alt: hsla(0, 0%, 0%, 0.9);
    --unit: 4px;
  }

  :root {
    --gutter: 40px;
  }

  :root {
    --ff-sans: 'Nike-Helvetica-Bold', system-ui, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', Oxygen,
      Cantarell, sans-serif;
    --ff-sans-alt: 'Nike-Canela-Thin', system-ui, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', Oxygen,
      Cantarell, sans-serif;
    --ff-serif: Times, Georgia, serif;
  }

  html {
    box-sizing: border-box;
    background: var(--color-bg-alt);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  ::-moz-selection {
    background: hsla(0, 0%, 0%, 0.3);
  }

  ::selection {
    background: hsla(0, 0%, 0%, 0.3);
  }

  body {
    min-height: 100vh;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-text);
    background: var(--color-bg);
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 300;
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3 {
  }

  a {
    color: hsla(0, 0%, 0%, 1);
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  strong {
    font-weight: 700;
  }

  ul {
    padding: 0;
  }

  ul li {
    list-style: none;
  }

  @font-face {
    font-family: 'Nike-Canela-Thin';
    src: url(${CanelaThin}) format('woff');
    font-weight: 200;
  }

  @font-face {
    font-family: 'Nike-Helvetica-Bold';
    src: url(${HelveticaBold}) format('woff');
    font-weight: 700;
  }

  .tl-edges {
    width: 100% !important;
    overflow: hidden !important;
    overflow-y: hidden !important;
  }
`;
