export type Size =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge';

export const SizeMultiplier: Record<Size, number> = {
  small: 2,
  medium: 4,
  large: 6,
  xlarge: 8,
  xxlarge: 10,
  xxxlarge: 12,
};
