import { css } from '@emotion/core';

export const root = css``;

export const wrapper = css`
  max-width: 840px;
  margin: 0 auto;
`;

export const title = css`
  max-width: 90px;
  margin-bottom: 50px;
`;

export const items = css`
  display: flex;
  flex-direction: column;
`;

export const item = css`
  margin-bottom: 50px;
  max-width: 660px;

  &:nth-child(2n) {
    margin-left: auto;
  }
`;

export const itemContent = css`
  max-width: 280px;
  padding-left: 20px;
`;

export const itemHeader = css`
  position: relative;
  margin-bottom: 30px;
`;

export const itemVisual = css`
  position: relative;
  height: 0;
  /* Formula is: (height / width * 100%) */
  padding-top: calc(360 / 360 * 100%);

  img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: auto;
  }
`;

export const itemTitle = css`
  position: absolute;
  bottom: 20px;
  left: 20px;
  max-width: 150px;
`;
