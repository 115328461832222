import { css } from '@emotion/core';
import { bp } from 'style';

export const root = css`
  max-width: 920px;
`;

export const title = css`
  max-width: 150px;
  margin-bottom: 30px;
`;

export const content = css`
  max-width: 260px;
  margin-bottom: 40px;
`;

export const items = css`
  @media ${bp.fromLarge} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const item = css`
  background: #f0f0f0;
  padding: 25px;
  margin-bottom: 14px;

  @media ${bp.fromLarge} {
    margin-right: 10px;
    flex: 1 0 360px;
  }
`;

export const itemCont = css``;

export const itemHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid black;
`;

export const sentence = css`
  @media ${bp.fromMedium} {
    min-height: 84px;
  }
`;

export const footer = css`
  margin-top: 120px;
`;

export const footerTitle = css`
  max-width: 160px;
  margin-bottom: 30px;
`;

export const footerAction = css`
  margin-top: 30px;
`;
