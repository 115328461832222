import { Header } from 'components';
import { PageProps } from 'gatsby';
import { changeLocale, IntlProvider } from 'gatsby-plugin-intl';
import getUserLocale from 'get-user-locale';
import { Languages } from 'modules/language';
import React, { useEffect } from 'react';

export const Layout: React.FC<PageProps> = ({
  children,
  location,
  ...props
}) => {
  const intl = props.pageContext as any;

  useEffect(() => {
    const userLocale = getUserLocale();

    /** Checks user locale based on browser settings. It returns fr for France, but fr-CA for canadian french, so it must be split and filtered only to get two letter language code, which is then user to change locale only on app startup. */
    if (
      Object.values(Languages).some((lang) => userLocale.split('-')[0] === lang)
    ) {
      const foundLocale = userLocale.split('-')[0];

      if (foundLocale === 'en' || foundLocale === 'de') {
        changeLocale(foundLocale);
      } else {
        changeLocale('en');
      }
    }
  }, []);

  return (
    <IntlProvider
      locale={intl.intl.language}
      defaultLocale={intl.intl.defaultLocale}
      messages={intl.intl.messages}
    >
      <Header location={location} />
      <main>{children}</main>
    </IntlProvider>
  );
};
