import { useIntl } from 'gatsby-plugin-intl';
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  image?: string;
  description?: string;
  lang?: string;
  title: string;
  url?: string;
  siteName?: string;
  creator?: string;
  urlEN?: string;
  urlDE?: string;
  urlIT?: string;
  urlNL?: string;
  urlFR?: string;
}

export const SEO: React.FC<Props> = ({
  description = 'Nike Bra Experience is a tool to help you choose a right Sports Bra support level for you.',
  lang = 'en',
  title,
  image = require('assets/images/nike-bra-share.jpg'),
  url,
  creator,
  siteName = 'Nike Bra Experience',
  urlEN,
  urlDE,
  urlIT,
  urlNL,
  urlFR,
}) => {
  // Grab current language from url
  const intl = useIntl();

  return (
    <Helmet
      htmlAttributes={{
        lang: intl?.locale || lang,
      }}
      link={[
        {
          rel: 'canonical',
          href: url,
        },
        {
          rel: 'alternate',
          hrefLang: 'en',
          href: urlEN,
        },
        {
          rel: 'alternate',
          hrefLang: 'fr',
          href: urlFR,
        },
        {
          rel: 'alternate',
          hrefLang: 'it',
          href: urlIT,
        },
        {
          rel: 'alternate',
          hrefLang: 'de',
          href: urlDE,
        },
        {
          rel: 'alternate',
          hrefLang: 'nl',
          href: urlNL,
        },
      ]}
      title={title}
      titleTemplate="%s | Nike Bra Experience"
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          name: 'og:image',
          content: image,
        },
        {
          name: 'image',
          property: 'og:image',
          content: image,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:creator',
          content: creator,
        },
        {
          name: 'twitter:image',
          content: image,
        },
        {
          name: 'og:site_name',
          content: siteName,
        },
        {
          name: 'theme-color',
          content: '#000000',
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black',
        },
      ]}
    />
  );
};
