export enum Sports {
  Dance = 'Dance',
  Running = 'Running ',
  Football = 'Football',
  Tennis = 'Tennis',
  Hiit = 'Hiit',
  Boxing = 'Boxing',
  CrossTraining = 'CrossTraining',
  Volleyball = 'Volleyball',
  HorsebackRiding = 'HorsebackRiding',
  FitnessClasses = 'FitnessClasses',
  Basketball = 'Basketball',
  Skiing = 'Skiing',
  WeightTraining = 'WeightTraining',
  Spinning = 'Spinning',
  Cardio = 'Cardio',
  Golf = 'Golf',
  Skateboarding = 'Skateboarding',
  Cycling = 'Cycling',
  Hiking = 'Hiking',
  Rowing = 'Rowing',
  YogaPilates = 'Yoga/Pilates',
  Barre = 'Barre',
  Handball = 'Handball',
}
