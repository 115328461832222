import { navigate } from 'gatsby';
import React from 'react';

interface Props {
  location: Location;
}

export const BackButton: React.FC<Props> = ({ location }) => {
  /** Don't show back button on index page */
  if (
    location.pathname === '/en/' ||
    location.pathname === '/' ||
    location.pathname === '/fr/' ||
    location.pathname === '/nl/' ||
    location.pathname === '/de/' ||
    location.pathname === '/it/' ||
    location.pathname.includes('find-your-fit') ||
    location.pathname.includes('measure-type') ||
    location.pathname.includes('measure-up') ||
    location.pathname.includes('rib-measure') ||
    location.pathname.includes('results')
  ) {
    return null;
  }

  return (
    <button onClick={handleGoBack}>
      <img src={require('assets/images/back-btn.svg')} alt="" />
    </button>
  );

  function handleGoBack() {
    navigate(-1);
  }
};
