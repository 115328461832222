import { SportInfo } from 'const';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { AlphaSizes } from 'modules/measurements';
import { AppState } from 'modules/redux-store';
import React from 'react';
import { useSelector } from 'react-redux';
import { buttons, results, spacing, titles, utils } from 'style';

import { SupportLevels } from '../models';

interface Props {
  supportLevel: SupportLevels;
  sport: SportInfo;
  alphaSize: AlphaSizes;
}

export const RecommendationText: React.FC<Props> = ({
  supportLevel,
  sport,
  alphaSize,
}) => {
  const inStoreExperience = useSelector(
    (state: AppState) => state.inStoreExperience.inStoreExperienceActive,
  );

  /**
   * Medium / Light / High support doesn't always produce the same string, it depends on the sport's impact which can be Light, Medium or High.
   */

  // const High_FullMedium = `Although ${sport.name} is a high impact sport we recommend a medium support compression sports bra for you.`;
  const High_FullMedium = (
    <FormattedMessage
      id="High_FullMedium"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );
  const High_MediumHigh = (
    <FormattedMessage
      id="High_MediumHigh"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );
  const High_FullHigh = (
    <FormattedMessage
      id="High_FullHigh"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  const Medium_MediumLight = (
    <FormattedMessage
      id="Medium_MediumLight"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  const Medium_FullMedium = (
    <FormattedMessage
      id="Medium_FullMedium"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  const Medium_MediumHigh = (
    <FormattedMessage
      id="Medium_MediumHigh"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  const Medium_FullHigh = (
    <FormattedMessage
      id="Medium_FullHigh"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  const Light_Light = (
    <FormattedMessage
      id="Light_Light"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  const Light_LightMedium = (
    <FormattedMessage
      id="Light_LightMedium"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  const Light_FullHigh = (
    <FormattedMessage
      id="Light_FullHigh"
      values={{
        sportName: (
          <strong>
            <FormattedMessage id={sport.nameStringId} />
          </strong>
        ),
      }}
    />
  );

  return (
    <div css={results.itemCont}>
      <div css={results.itemHeader}>
        {sport.iconElement}
        <img src={require('assets/images/nike-icon.svg')} alt="" />
      </div>

      {renderCorrectText()}
    </div>
  );

  function filterTextsForHigh() {
    switch (supportLevel) {
      case 'Medium':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="medium" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {High_FullMedium}
            </p>
            {renderMediumSupportLink()}
          </>
        );
      case 'Medium or High':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="high" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {High_MediumHigh}
            </p>
            {renderHighSupportLink()}
          </>
        );
      case 'High':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="high" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {High_FullHigh}
            </p>
            {renderHighSupportLink()}
          </>
        );
      default:
        return null;
    }
  }

  function filterTextsForMedium() {
    switch (supportLevel) {
      case 'Medium or Light':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="medium" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {Medium_MediumLight}
            </p>
            {renderMediumSupportLink()}
          </>
        );
      case 'Medium':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="medium" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {Medium_FullMedium}
            </p>
            {renderMediumSupportLink()}
          </>
        );
      case 'Medium or High':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="medium" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {Medium_MediumHigh}
            </p>
            {renderHighSupportLink()}
          </>
        );
      case 'High':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="high" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              Size <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {Medium_FullHigh}
            </p>
            {renderHighSupportLink()}
          </>
        );

      default:
        return null;
    }
  }
  function filterTextsForLight() {
    switch (supportLevel) {
      case 'Light':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="light" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {Light_Light}
            </p>
            {renderLightSupportLink()}
          </>
        );
      case 'Light or Medium':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="light" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {Light_LightMedium}
            </p>
            {renderMediumSupportLink()}
          </>
        );
      case 'Full High':
        return (
          <>
            <p css={[titles.tertiary, utils.fontFamilySansAlt]}>
              <FormattedMessage id="high" /> <FormattedMessage id="support" />
            </p>
            <p css={[titles.tertiary, spacing.bottom.small]}>
              <FormattedMessage id="size" /> <br />
              {alphaSize}
            </p>
            <p
              css={[
                utils.typeMedium,
                utils.fontFamilySansAlt,
                spacing.top.xlarge,
                results.sentence,
              ]}
            >
              {Light_FullHigh}
            </p>
            {renderHighSupportLink()}
          </>
        );
      default:
        return null;
    }
  }

  function renderCorrectText() {
    switch (sport.impact) {
      case 'High':
        return filterTextsForHigh();
      case 'Medium':
        return filterTextsForMedium();
      case 'Light':
        return filterTextsForLight();
      default:
        return null;
    }
  }

  function renderLightSupportLink() {
    if (inStoreExperience) {
      return null;
    }
    return (
      <a
        css={[
          buttons.base,
          buttons.narrowButton,
          buttons.dark,
          spacing.top.xlarge,
        ]}
        target="_blank nooopener noreferrer"
        href="https://sportscheck.com/nike/unterwaesche/sport-bhs/halt-leicht/"
      >
        <FormattedMessage id="buyNowCta" />
      </a>
    );
  }
  function renderMediumSupportLink() {
    if (inStoreExperience) {
      return null;
    }

    return (
      <a
        css={[
          buttons.base,
          buttons.narrowButton,
          buttons.dark,
          spacing.top.xlarge,
        ]}
        target="_blank nooopener noreferrer"
        href="https://sportscheck.com/nike/unterwaesche/sport-bhs/halt-mittel/rtb32303230/"
      >
        <FormattedMessage id="buyNowCta" />
      </a>
    );
  }
  function renderHighSupportLink() {
    if (inStoreExperience) {
      return null;
    }

    return (
      <a
        css={[
          buttons.base,
          buttons.narrowButton,
          buttons.dark,
          spacing.top.xlarge,
        ]}
        target="_blank nooopener noreferrer"
        href="https://sportscheck.com/nike/unterwaesche/sport-bhs/halt-stark/rtb32303230/"
      >
        <FormattedMessage id="buyNowCta" />
      </a>
    );
  }
};
