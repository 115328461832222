import { Sports } from '../models';
import { SportAction } from '.';
import { SportActionTypes } from './types';

export interface SportsState {
  sports: Sports[];
}

const INITIAL_STATE: SportsState = {
  sports: [],
};

export const SportsReducer = (
  state: SportsState = INITIAL_STATE,
  action: SportAction,
): SportsState => {
  switch (action.type) {
    case SportActionTypes.AddSport:
      return {
        ...state,
        sports: state.sports.concat(action.payload.sport),
      };
    case SportActionTypes.RemoveSport:
      return {
        ...state,
        sports: state.sports.filter((sport) => sport !== action.payload.sport),
      };
    case SportActionTypes.CleanAllSports:
      return {
        ...state,
        sports: [],
      };
    default:
      return state;
  }
};
