import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { footer, utils } from 'style';

export const Footer: React.FC = () => {
  const intl = useIntl();

  return (
    <footer css={footer.root}>
      <div css={utils.wrapper}>
        <ul>
          <li>
            <div css={[utils.typeMedium, utils.fontFamilySansAlt]}>
              2020 © Nike, Inc
            </div>
          </li>
          <li>
            <a
              target="_blank noopener noreferrer"
              href={intl.formatMessage({ id: 'privacyPolicyLink' })}
              css={[utils.typeMedium, utils.fontFamilySansAlt]}
            >
              <FormattedMessage id="privacyAndCookiePolicy" />
            </a>
          </li>
          <li>
            <a
              target="_blank noopener noreferrer"
              href={intl.formatMessage({ id: 'termsOfUseLink' })}
              css={[utils.typeMedium, utils.fontFamilySansAlt]}
            >
              <FormattedMessage id="termsOfUse" />
            </a>
          </li>
          <li>
            <Link
              to="/cookie-settings"
              css={[utils.typeMedium, utils.fontFamilySansAlt]}
            >
              <FormattedMessage id="cookiePopupSettingsCta" />
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};
